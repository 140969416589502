import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  Wearable,
  MainContainer,
  Navbar,
  AfterEventContainer,
  StepContainer,
  StepNumber,
  HeroContainer,
  InnerContainer,
  InfoContainer,
  BannerContainer
} from "./styles";

import appStoreLogo from "../../assets/app-store.svg";
import gplayLogo from "../../assets/play-store.svg";
import curveLogo from "../../assets/curve_logo.png";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import chevronUp from "../../assets/chevron-up.svg";
import chevronDown from "../../assets/chevron-down.svg";
import copyToClipboard from "../../assets/copy-icon.svg";
import payAnyWhereImage from "./img/payanywhere.webp";
import infineonLogo from "./img/infineon.png";
import linkedinLogo from "../../assets/linkedin.svg";
import twitterLogo from "../../assets/twitter.svg";
import facebookLogo from "../../assets/facebook.svg";
import fingernailImage from "./img/fingernail.webp";
import electronica25banner from "./img/electronica25.webp";

import packageJson from "../../../package.json";

const Electronica25 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const [showPaymentAccount, setShowPaymentAccount] = useState(true);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={infineonLogo} alt="infineon-logo" />
        {/* <div className="balance">
          <p>Balance</p>
          <p>{`${formattedBalance(
            balanceAndPin?.balanceDetails?.availableBalance,
            balanceAndPin?.balanceDetails?.currency
          )}`}</p>
        </div> */}
      </Navbar>
      <BannerContainer>
        <img src={electronica25banner} alt="electronica25" />
      </BannerContainer>
      <Wearable className="wearable">
        <img src={fingernailImage} alt="wearable" />
      </Wearable>

      <InnerContainer>
        <HeroContainer>
          <p>
            恭喜您成为由英飞凌、Smart Chip和 DIGISEQ 提供支持的全球首款
            NFC智能指甲的佩戴者。立即体验这款新颖的支付方式！
          </p>
        </HeroContainer>
        <InfoContainer>
          <h2>Visit Infineon</h2>
          <p>访问英飞凌官网，了解更多支付解决方案。</p>
          <a
            href="https://www.infineon.com/cms/cn/applications/security-solutions/payment-solutions/?utm_campaign=ci19810001_202504_gc_cn_cp.e.electronica&utm_medium=socialmediaorganic&utm_source=wechat_textpost&utm_content=_&utm_term="
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </InfoContainer>
        <AfterEventContainer>
          <>
            <StepContainer>
              <StepNumber>1</StepNumber>
              <h2>Download the Smart Chip app to:</h2>

              <p>Link your Curve card to enable contactless payments.</p>
              <p>
                Set up a contactless business card and share your details with a
                tap!
              </p>
              {appStoreLinks(
                "https://apps.apple.com/gb/app/sc-smart-chip/id6651851137",
                "https://play.google.com/store/apps/details?id=com.kore.smartchip"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>2</StepNumber>
              <h2>Pay Anywhere!</h2>
              <p>
                Tap your Smart Chip at any card terminal where Mastercard
                Contactless is enabled.
              </p>

              <img
                className="pay-anywhere"
                src={payAnyWhereImage}
                alt="pay anywhere"
              />
              <p>Need help? Check FAQ in Smart Chip app!</p>
            </StepContainer>
          </>
        </AfterEventContainer>
        <InfoContainer>
          <h2>关于Smart Chip</h2>
          <p>
            在DIGISEQ的支持下，Smart Chip
            公司于2024年开发并推出了世界上第一款带有 RFID 芯片的 NFC
            美甲套餐。有了这款指尖智能芯片，您就可以随时随地享受丝滑的支付体验。
          </p>
          <a href="https://smart-chip.com/" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </InfoContainer>
        <InfoContainer>
          <h2>
            Who are <span className="emphasized">DIGISEQ?</span>
          </h2>
          <p>
            DIGISEQ
            是一个屡获殊荣的物联网服务平台，可为日常可穿戴设备提供安全可靠的数据，使从戒指到服装的各种物品都能提供
            NFC 支付、客户参与功能、访问控制和数字身份。
          </p>
          <a href="https://www.digiseq.co.uk" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </InfoContainer>
      </InnerContainer>

      <Footer>
        <div>
          <h2 className="emphasized">DIGISEQ</h2>
          <h2>Revolutionising the Way We Pay.</h2>
        </div>
        <ul className="links">
          <li>
            <a
              href="https://www.digiseq.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              <p>Our Website</p>
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.digiseq.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li> */}
          <li>
            <a
              href="https://digiseq.co.uk/terms-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              <p>Terms and Conditions</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.digiseq.co.uk/wearables-and-psd2-sca"
              target="_blank"
              rel="noreferrer"
            >
              <p>Wearables and PSD2 SCA</p>
            </a>
          </li>
        </ul>
        <ul className="socials">
          <li>
            <a
              href="https://www.linkedin.com/company/digiseq"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinLogo} alt="linkedin logo" />
            </a>
          </li>
          <li>
            <a href="https://x.com/digisequk" target="_blank" rel="noreferrer">
              <img src={twitterLogo} alt="twitter logo" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Digiseq/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookLogo} alt="facebook logo" />
            </a>
          </li>
        </ul>
        <div className="copyToClipboard">
          <img
            onClick={() => {
              navigator.clipboard.writeText(serialNumber);
              setCopied(true);
            }}
            src={copyToClipboard}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
      </Footer>
    </MainContainer>
  );
};

export default Electronica25;
